import { FC } from 'react';

const HomeInfoBlock: FC = () => {
  return (
    <>
      <div className="contentHeader">
        <div className="contentHeader__item">
          <b>Электронная библиотека bookZ.ru - скачать книги бесплатно!</b>
        </div>
      </div>
      <div className="content">
        <div className="content__txt">
          <p className="content__txtItem">В электронной библиотеке bookz.ru вы можете бесплатно скачать книги, справочники, журналы и словари в электронном виде.</p>
          <p className="content__txtItem">Подписавшись на рассылку "Новинки", вы всегда будете в курсе последних новинок на сайте.</p>
          <p className="content__txtItem">Оставляйте свои отзывы, комментарии и делитесь впечатлениями от книг, ставьте оценки прочитанным произведениям на страницах книг.</p>
        </div>
      </div>
    </>
  );
};
export default HomeInfoBlock;
