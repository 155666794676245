import { FC, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styles from './HomeSearchBlock.module.scss';

const HomeSearchBlock: FC = () => {
  const router = useRouter();

  const [inputSearch, setInputSearch] = useState('');
  // const [isAuthorSearch, setIsAuthorSearch] = useState(false);

  const searchWord = inputSearch.includes(' ') ? inputSearch.split(' ').join('+') : inputSearch;

  const getSearchWord = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const searchByPressEnter = (event: React.KeyboardEvent<object>) => {
    if (String(event.code) == 'Enter') {
      // router.push(`/search/${isAuthorSearch ? 'author' : 'book'}/${searchWord}`);
      router.push(`/search/${searchWord}`);
    }
  };

  return (
    <>
      <div className="contentHeader">
        <div className="contentHeader__item">
          <b>Поиск электронных книг</b>
        </div>
      </div>
      <div className="content">
        <div className="content__txt">
          Мы организовали электронную библиотеку так, чтобы Вы максимально быстро и точно нашли и скачали книгу, которую искали. Даже если вы ничего не нашли, администраторы узнают об этом и в течение недели постараются добавить искомое. Если Вы не
          уверены точно в имени автора или названии книги, то лучше введите одно слово, в котором точно уверены или даже его часть, например, при поиске Дэн Браун/Ден Браун лучше просто ввести браун и Вы обязательно найдете автора.
        </div>
        <div className={styles.searchWrap}>
          <div className={styles.search}>
            <input className={styles.search__input} type="text" placeholder="Поиск..." value={inputSearch} onChange={getSearchWord} onKeyDown={searchByPressEnter} />
            {!!inputSearch.length ? (
              // <Link prefetch={false} href={`/search/${searchWord}/${isAuthorSearch ? 'authors' : 'books'}`} className={styles.search__button}>
              //   Найти
              // </Link>
              <Link prefetch={false} href={`/search/${searchWord}`} className={styles.search__button}>
                Найти
              </Link>
            ) : (
              <span className={styles.search__button}>Найти</span>
            )}
          </div>
          {/* <div data-search-menu className={styles.searchControls__radios}>
            <div>
              <label className={styles.searchControls__searchLabel}>
                <input className={styles.searchControls__searchInput} name="type" type="radio" defaultChecked onChange={() => setIsAuthorSearch(false)} />
                <span className={styles.searchControls__searchTxt}>По книге</span>
              </label>
              <label className={styles.searchControls__searchLabel}>
                <input className={styles.searchControls__searchInput} name="type" type="radio" onChange={() => setIsAuthorSearch(true)} />
                <span className={styles.searchControls__searchTxt}>По автору</span>
              </label>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default HomeSearchBlock;
